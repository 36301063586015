@import "themes/constants.module.scss";

body {
	--color-neutral-1: #{$color-green-CEA};
	--color-neutral-1-dark: #{$color-green-CEA-dark};
	--color-neutral-1-hover: #{$color-green-CEA-light};

	--color-transparent-1: #{$color-transparent-1};
	--color-transparent-2: #{$color-transparent-2};
	--color-transparent-3: #{$color-transparent-3};

	--color-generic-white: #{$color-generic-white};
	--color-generic-black: #{$color-generic-black};
}

[theme-mode] {
	--color-text-1: #{$color-generic-black};
	--color-text-2: #{$color-generic-white};
	--color-primary-1: #{$color-generic-white};
	--color-primary-2: #{$color-generic-black};
	--color-background-1: #{$color-generic-white};
	--color-background-2: #{$color-grayscale};
	--color-transparent-1: #{$color-transparent-1};
	--color-transparent-2: #{$color-transparent-2};
	--color-transparent-3: #{$color-transparent-3};
}
