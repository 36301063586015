@import "themes/constants.module.scss";
@import "themes/fonts.scss";
@import "themes/modes.scss";
@import "themes/variables.scss";

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-background-1);
	color: var(--color-text-1);
	font-family: var(--font-primary);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-size: 0;
	overflow-x: hidden;
	max-width: 100vw;

	#root {
		display: flex;
		flex-direction: column;
		height: 100vh;
	}
}

* {
	box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: white !important;
	-webkit-background-clip: text;
	background-clip: text;
}

label.required::after {
	content: "*";
}

select {
	width: 100%;
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: "";

	background-color: var(--color-background-1);
	&:focus-visible {
		outline: none;
		border: none;
	}

	option {
		padding: 10px 0;
	}
}

input:placeholder-shown {
	text-overflow: ellipsis;
}

a {
	text-decoration: none;
}
